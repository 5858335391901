.banner {
  background: linear-gradient(to right, #1e1e1e, #000);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  width: 100%;
  font-family: var(--font-primary);
  text-align: center;
  scroll-snap-align: start;
}

.content {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
}

/* Heading Text */
.heading {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: clamp(2.5rem, 8vw, 9rem); /* Scales based on viewport */
  font-weight: bold;
  line-height: 1.2;
  color: #e5e5e5;
  margin-bottom: 1rem;
  font-family: var(--font-primary);
}

.highlightedText {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: clamp(2.5rem, 8vw, 9rem);
  font-weight: bold;
  line-height: 1.2;
  color: #c4a000;
  font-family: var(--font-primary);
}

/* Launch Section */
.launchSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 1rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.storeIcon {
  width: 30px;
  height: 30px;
  color: white;
}

.launchIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner {
    padding: 3rem 1rem;
  }

  .heading,
  .highlightedText {
    text-align: center;
    justify-content: center;
  }

  .launchSection {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 2rem 1rem;
  }

  .heading,
  .highlightedText {
    font-size: clamp(2rem, 10vw, 5rem);
  }

  .storeIcon {
    width: 24px;
    height: 24px;
  }
}
