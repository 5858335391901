.footer {
  background-color: #000;
  color: #fff;
  padding: 2rem 4rem 1rem 4rem;
  font-size: 14px;
  width: 100%;
  scroll-snap-align: start;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin: 0 auto;
}

.socialIcons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.icon {
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
  color: #C9C9C9;
}

.icon:hover {
  color: #d4af37;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copyright {
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.syncup {
  font-size: 20px;
  margin-right: 10px;
  color: #FFFFFF;
}

.logo {
  width: 24px;
  height: auto;
  margin-right: 10px;
  color: #FFFFFF;
}

.copyrightText {
  font-size: 16px;
  color: #C9C9C9;
  font-family: 'Gotham';
}

.policyLinks {
  display: flex;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
  font-family: 'Gotham';
}

.link {
  color: #C9C9C9;
  text-decoration: none;
  font-size: 16px;
  transition: opacity 0.3s ease;
}

.link:hover {
  color: #d4af37;
}


@media (max-width: 1224px) {
  .footer {
    padding: 2rem;
  }

  .socialIcons {
    justify-content: center;
  }

  .bottomSection {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }

  .policyLinks {
    margin-top: 10px;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .container {
    align-items: center;
    text-align: center;
  }

  .socialIcons {
    justify-content: center;
  }

  .copyright {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }

  .policyLinks {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1.5rem;
  }

  .copyrightText,
  .syncup,
  .link {
    font-size: 16px;
  }

  .logo {
    width: 32px;
  }

  .syncup {
    font-size: 24px;
  }

  .policyLinks {
    flex-direction: column;
    gap: 8px;
  }

  .socialIcons {
    gap: 10px;
  }

  .copyrightText {
    font-size: 0.7rem;
  }
}
