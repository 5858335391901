@font-face {
    font-family: 'Didot';
    src: url('/public/assets/fonts/didot-2/Didot.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


  /* @font-face {
    font-family: 'Bodoni';
    src: url('/assets/fonts/Bodoni.woff2') format('woff2'),
         url('/assets/fonts/Bodoni.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  } */
  
  @font-face {
    font-family: 'Gotham';
    src: url('/public/assets/fonts/gotham/Gotham-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  /*
  @font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/AvenirNext.woff2') format('woff2'),
         url('/assets/fonts/AvenirNext.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  } */

@font-face {
    font-family: 'Futura';
    src: url('/public/assets/fonts/Futura/FuturaSCTOT-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}