.heroContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Futura';
  /* justify-content: center; */
  overflow: hidden;
  padding: 2rem;
  background: url("/public/assets/images/hero-background.avif") no-repeat center center/cover;
  scroll-snap-align: start;
}

.header {
  position: absolute;
  top: 20px;
  right: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  position: absolute;
  top: 3.5rem;
  left: 4rem;
  font-size: 32px;
  z-index: 10000;
  font-family: 'Futura';
  color: #fff;
}

.earlyAccess {
  position: absolute;
  top: 4rem;
  right: 2rem;
  background: transparent;
  border: 1px solid #d4af37;
  padding: 12px 24px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: 'Futura';
  /* font-weight: 100; */
  border-radius: 0;
  z-index: 1000;
}

.earlyAccess:hover {
  background: #d4af37;
  color: #000;
}

.decorLineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-bottom: 20px; */
}

.leftLine,
.rightLine {
  flex: 0.4;
  height: 2px;
}

.leftLine {
  background: linear-gradient(to right, transparent, #c4a000);
}

.rightLine {
  background: linear-gradient(to left, transparent, #c4a000);
}

.syncupIcon {
  width: 40px;
  height: auto;
  margin: 0 10px;
}

.carouselWrapper {
  width: 100%;
  max-width: 1400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.card {
  position: absolute;
  width: 300px;
  height: 420px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  border: 2px solid #f5f5f5;
}

.active {
  border: 2px solid #f5f5f5;
}

.adjacent {
  border: 2px solid #f5f5f5;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  filter: grayscale(100%) blur(12px);
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.heroText {
  position: absolute;
  bottom: 60px;
  font-size: 48px;
  color: white;
  text-align: center;
}

.highlight {
  color: #d4af37;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .card {
    width: 260px;
  }
}

@media (max-width: 992px) {
  .card {
    width: 250px;
  }

  .heroText {
    bottom: 8px;
  }
}

@media (max-width: 768px) {
  .card {
    width: 220px;
  }
}


@media (max-width: 768px) {
  .carousel {
    justify-content: center;
    align-items: center;
  }

  .card {
    width: 260px;
    height: 390px;
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  }

  .active {
    width: 280px;
    height: 420px;
    opacity: 1;
  }

  .adjacent {
    width: 270px;
    height: 430px;
    opacity: 1;
  }

  .card img {
    filter: grayscale(100%) blur(8px);
  }

  .heroText {
    font-size: 32px;
    bottom: 32px;
  }

  .earlyAccess {
    padding: 8px 15px;
    font-size: 14px;
  }

  .earlyAccess {
    right: 0;
  }
}

@media (max-width: 390px) {
  .heroContainer {
    padding: 1rem 0;
  }
  .card {
    width: 260px;
  }

  .heroText {
    font-size: 24px;
  }

  .logo {
    top: 4rem;
    font-size: 20px;
  }
}