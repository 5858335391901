.errorText {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  border-bottom: 2px solid red !important;
}
.earlyAccess {
  background-color: black;
  color: #c4a000;
  padding: 50px 20px;
  text-align: center;
  font-family: var(--font-primary);
  height: 100vh;
  scroll-snap-align: start;
}

.decorLineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.leftLine,
.rightLine {
  flex: 0.4;
  height: 2px;
}

.leftLine {
  background: linear-gradient(to right, transparent, #c4a000);
}

.rightLine {
  background: linear-gradient(to left, transparent, #c4a000);
}

.syncupIcon {
  width: 40px;
  height: auto;
  margin: 0 10px;
}

.heading {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 4rem;
  text-align: center;
  color: #c4a000;
  margin-top: 3rem;
  font-family: var(--font-primary);
}

.formContainer {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  border: 1px dotted #EAEAEA; 
  max-width: 1200px;
  padding: 4rem 2rem;
  margin: 0 auto;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  font-family: var(--font-primary);
  animation: fadeInUp 0.8s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.formContainer.flipped {
  transform: rotateY(180deg);
  transition: transform 0.6s ease-in-out;
}

.syncupLogo {
  position: absolute;
  right: 80px;
  top: 60px;
  width: 65px;
  height: auto;
}

.formHeading {
  color: white;
  font-size: 54px;
  margin-bottom: 4rem;
  font-family: var(--font-primary);
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0 4rem;
  font-family: var(--font-gotham);
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 144px;
}

.inputGroup {
  position: relative;
  width: 100%;
}

.inputGroup input {
  background: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  padding: 10px 0;
  font-size: 16px;
  outline: none;
  width: 100%;
  font-family: var(--font-gotham);
  border-radius: 0;
  outline: 0;
}

.inputGroup input:focus {
  border-bottom: 1px solid white;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: white;
  font-size: 14px;
  margin-top: 1rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  border-radius: 20px;
  border: 1px solid white;
  padding: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #c4a000;
}

.switch input:checked + .slider:before {
  transform: translateX(18px);
}

.termsText {
  color: #fff;
  font-size: 20px;
}

.termsWarning {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  display: none;
}

.submitButton {
  position: relative;
  padding: 12px 24px;
  background: transparent;
  color: #c4a000;
  font-size: 1.2rem;
  border: 1px solid #c4a000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
  font-family: 'Gotham';
  width: 24%;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: unset;
}

.submitButton:hover {
  background: #d4af37;
  color: #000;
}

.submitButton:disabled {
  cursor: not-allowed;
  background: #999;
}

.successContainer {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  border: 1px dotted #EAEAEA; 
  max-width: 1200px;
  padding: 4rem 2rem;
  margin: 0 auto;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  font-family: var(--font-primary);
  animation: fadeInUp 0.8s ease-out forwards;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.successLogo {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

.successHeading {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  color: #c4a000;
  font-family: var(--font-primary);
} 

.successText {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4rem;
  text-align: center;
  color: #c4a000;
  font-family: var(--font-primary);
}

@media (max-width: 768px) {
  .earlyAccess {
    height: unset;
  }

  .syncupLogo {
    width: 40px;
    right: 50%;
    translate: 50%;
    top: 40px;
  }
  .formHeading {
    font-size: 32px;
    margin-top: 6rem;
    margin-bottom: 2rem;
  }

  .formContainer {
    padding: 1rem;
  }

  .form {
    padding: 2rem;
    gap: 32px;
  }

  .heading {
    font-size: 32px;
  }

  .row {
    flex-direction: column;
    gap: 32px;
  }

  .inputGroup input {
    font-size: 18px;
  }

  .submitButton {
    width: 50%;
    font-size: 1rem;
    padding: 8px 16px;

  }

  .successLogo {
    width: 60px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 28px;
    height: auto;
  }
  .syncupLogo {
    width: 40px;
    right: 50%;
    translate: 50%;
    top: 40px;
  }
  .formHeading {
    font-size: 32px;
    margin-top: 6rem;
  }

  .form {
    padding: 1rem;
  }

  .inputGroup input {
    font-size: 16px;
  }

  .termsText {
    font-size: 0.8rem;
  }

  .submitButton {
    width: 80%;
  }

  .slider:before {
    height: 12px;
    width: 12px;
  }

  .switch {
    width: 32px;
    height: 16px;
  }

  .slider {
    padding: 8px;
    
  }

  .switch input:checked + .slider:before {
    transform: translateX(14px);
  }

  .successContainer {
    height: 360px;
  }
  
  .successLogo {
    width: 48px;
    margin-top: 2rem;
  }
  
  .successHeading {
    font-size: 36px;
  } 
  
  .successText {
    font-size: 20px;
  }

}
