.container {
  background: #000;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  scroll-snap-align: start;
  overflow-y: hidden;
  position: relative;
}

.title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  font-family: var(--font-primary);
}

.subtitle {
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 2rem;
  opacity: 0.8;
  max-width: 900px;
}

.decorLineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  width: 100%;

}

.leftLine,
.rightLine {
  flex: 0.4;
  height: 2px;
}

.leftLine {
  background: linear-gradient(to right, transparent, #c4a000);
}

.rightLine {
  background: linear-gradient(to left, transparent, #c4a000);
}

.syncupIcon {
  width: 40px;
  height: auto;
  margin: 0 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 3rem;
  width: 90%;
  max-width: 1200px;
}

.card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 280px;
  border-bottom: 1px solid white;
  border: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: bottom 0.4s ease-in-out;
}

.overlayContent {
  text-align: center;
  color: white;
  padding: 10px;
}

.text {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.description {
  font-size: 1rem;
  font-weight: 300;
  opacity: 0.9;
  max-width: 100%;
}

/* --- Hover Effects --- */
.card:hover .overlay {
  bottom: 0;
}

/* --- Button Styling --- */
.button {
  position: relative;
  padding: 12px 24px;
  background: transparent;
  color: #c4a000;
  font-size: 1.2rem;
  border: 1px solid #c4a000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
  font-family: 'Gotham';
  width: 14%;
  margin: 0 auto;
  margin-top: 2rem;
  border-radius: unset;
}


.submitButton {
  position: relative;
  padding: 12px 24px;
  background: transparent;
  color: #c4a000;
  font-size: 1.2rem;
  border: 1px solid #c4a000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
  font-family: 'Gotham';
  width: 24%;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: unset;
}

.button:hover {
  background: #d4af37;
  color: #000;
}

@media (max-width: 480px) {

  .container {
    height: fit-content;
  }

  .grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .button {
    font-size: 14px;
    padding: 8px 16px;
  }
}